<template>
  <div class="page">
    <!-- <div class="home-icon" @click.stop="goBack()">
      <img src="~@/assets/ic_back_28.png" alt />
    </div> -->
    <!-- <div class="collect-icon" @click="backHome()">
      <img src="~@/assets/icon_shangpin_home@2x.png" alt />
    </div> -->
    <img src="../../assets/zhZlTop.png" class="top1" />
    <img src="../../assets/zhZlText.png" class="text1" />
    <img src="../../assets/zhZlbttom.png" class="bottom1" />
    <div v-if="isfirst">
      <div class="clickisTrue" @click="clickisTrue" v-if="clickisTrueType">
        <img src="../../assets/WechatIMG15.png" />
      </div>
      <div class="clickSucss" v-if="!clickisTrueType">
        <p>您是第{{ number }}位华政守“沪”人，将获赠蒙牛纯牛奶6盒</p>
      </div>
    </div>

    <p class="lastBottom">*本活动仅限华东政法大学松江校区师生参与</p>
  </div>
</template>

<script>
import {
  getCardRule,
  zHbocClickInfo,
  zHbocClick
} from "@/services/cardMallApi";
import { customAccount } from "../../utils/custom";
import { getStore } from "@/utils/common";
// import HeadNav from "@/components/common/HeadNav"
export default {
  name: "ExchangeRules",
  data() {
    return {
      content: "", // 兑换规则
      number: 100,
      ischeck: true,
      clickisTrueType: true,
      isfirst: false,
      retries: 0
    };
  },
  async created() {
    await this.clickSucss();
  },
  mounted() {
    this.$nextTick(() => {
      document.title = "爱暖华政，上海加油！";
    });
  },
  methods: {
    async clickisTrue() {
      if (this.ischeck) {
        let result = await zHbocClick();
        this.ischeck = false;
        if (result.code == 0) {
          await this.clickSucss();
          this.clickisTrueType = false;
          this.ischeck = true;
        } else {
          this.ischeck = true;
        }
      }
    },
    async clickSucss() {
      this.retries++
      if (this.retries > 10) {
        return
      }
      try {
        let result = await zHbocClickInfo();
        if (result.code == 0) {
          if (result.data.click_sort == 0) {
            this.clickisTrueType = true;
          } else {
            this.clickisTrueType = false;
          }
          this.number = result.data.click_sort;
          this.isfirst = true;
          return false;
        } else {
          this.clickSucss();
        }
      } catch (error) {
        this.clickSucss();
      }
    },
    customAccount,
    goBack() {
      this.$router.go(-1);
    },
    backHome() {
      this.$router.replace({
        path: "/home"
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
.clickisTrue {
  width: 54%;
  position: absolute;
  z-index: 999;
  margin-left: 23%;
  bottom: 13%;
}
.clickSucss {
  width: 76%;
  position: absolute;
  height: 15%;
  margin-left: 12%;
  background-image: url("../../assets/WechatIMG14.png");
  background-size: 100% 100%;
  bottom: 10%;
  p {
    width: 80%;
    margin-left: 10%;
    position: absolute;
    bottom: 11%;
    font-size: 0.3rem;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    color: #7b2900;
  }
}
.top1 {
  width: 100%;
  //   position: absolute;
}
.text1 {
  width: 100%;
  //   position: absolute;
  left: 0;
  margin-top: 5%;
}
.bottom1 {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.lastBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.2rem;
  color: #000;
  font-weight: 400;
  background-color: #fff;
  letter-spacing: 2px;
}
.page {
  width: 100%;
  height: 100%;
  background: url("../../assets/zhZlbackground.png") repeat-y;
  background-size: 100% 100%;
  //   padding: 0.32rem;
  box-sizing: border-box;
  .content {
    .sc(@fontsize-large, @color-dark-grey);
    line-height: 0.44rem;
    margin-top: 0.7rem;
  }
  .bgimg {
    width: 100%;
    top: 0;
    position: absolute;
  }
  .home-icon,
  .collect-icon {
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.56rem;
    position: absolute;
    z-index: 110;

    img {
      width: 0.56rem;
      height: 0.56rem;
    }
  }

  .home-icon {
    left: 0.3rem;
    top: 0.4rem;
  }

  .collect-icon {
    right: 0.3rem;
    top: 0.4rem;
  }
}
</style>
