var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("img", {
      staticClass: "top1",
      attrs: { src: require("../../assets/zhZlTop.png") },
    }),
    _c("img", {
      staticClass: "text1",
      attrs: { src: require("../../assets/zhZlText.png") },
    }),
    _c("img", {
      staticClass: "bottom1",
      attrs: { src: require("../../assets/zhZlbttom.png") },
    }),
    _vm.isfirst
      ? _c("div", [
          _vm.clickisTrueType
            ? _c(
                "div",
                { staticClass: "clickisTrue", on: { click: _vm.clickisTrue } },
                [
                  _c("img", {
                    attrs: { src: require("../../assets/WechatIMG15.png") },
                  }),
                ]
              )
            : _vm._e(),
          !_vm.clickisTrueType
            ? _c("div", { staticClass: "clickSucss" }, [
                _c("p", [
                  _vm._v(
                    "您是第" +
                      _vm._s(_vm.number) +
                      "位华政守“沪”人，将获赠蒙牛纯牛奶6盒"
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("p", { staticClass: "lastBottom" }, [
      _vm._v("*本活动仅限华东政法大学松江校区师生参与"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }